/* General Styles */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

/* Typography */
body {
  line-height: 1.6;
  color: #333; /* Dark gray for text */
  margin: 0; /* Remove default body margins */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600; /* Slightly bolder for emphasis */
  margin-bottom: 1rem; /* Space below headings */
}

p {
  margin-bottom: 1rem; /* Improve readability with paragraph spacing */
}

/* Links */
a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* Section Styling */
section {
  padding: 40px 0; /* Adjusted for better balance */
  margin-bottom: 20px; /* Space between sections */
}

section:last-child {
  /* Remove bottom margin from the last section */
  margin-bottom: 0;
}

/* Header Styling (Optional) */
header {
  background-color: #fff; /* Example: white background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
}

/* Footer Styling (Optional) */
footer {
  background-color: #333; /* Example: dark background */
  color: #fff;
  padding: 20px;
  text-align: center;
}

/* Responsive Design (Adjust breakpoints as needed) */
@media (max-width: 768px) {
  section {
    padding: 30px 0;
  }
}

.wave {
  position: absolute;
  bottom: -10px; /* Adjust position as needed */
  left: 0;
  width: 100px; /* Adjust width as needed */
  height: 5px;
  background-color: #007bff; /* Blue color */
  opacity: 0;
  transition: opacity 0.5s ease, left 1s ease; /* Smooth transitions */
}
