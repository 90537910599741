/* General Styles */
body {
  font-family: "Courier New", sans-serif;
  margin: 0;
  line-height: 1.6;
  color: #333;
  background-color: #f5f5f5;
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
  font-weight: 500;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* Section Styles */
section {
  padding: 60px 0;
}

/* Header/Navbar Styles */
.MuiAppBar-root {
  padding: 10px 20px;
}

/* Footer Styles */
.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer .MuiIconButton-root {
  margin: 0 10px;
}
