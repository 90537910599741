/* heroSection.css */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  background-color: #080808;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.hero-content {
  z-index: 2;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.6;
  filter: hue-rotate(190deg) saturate(180%) brightness(80%) blur(5px);
}

.hero-content h2 .cursor {
  border-right: 2px solid white;
  animation: blink 1s infinite;
  padding-left: 5px;
}

.hero-content h2 .blinking {
  animation: none;
  animation: blinkSlow 2s infinite;
  padding-left: 5px;
}

@keyframes blink {
  0% {
    border-right-color: white;
  }
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: white;
  }
}

@keyframes blinkSlow {
  0% {
    border-right-color: white;
  }
  75% {
    border-right-color: white;
  }
  100% {
    border-right-color: transparent;
  }
}
